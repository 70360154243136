/* ---------------------------------- Base ---------------------------------- */
/* ---------------------------------- Text ---------------------------------- */
/* --------------------------------- Background -------------------------------- */
/* --------------------------------- Buttons -------------------------------- */
/* ---------------------------------- Cards --------------------------------- */
/* --------------------------------- Inputs --------------------------------- */
/* ---------------------------------- Utils --------------------------------- */
:root {
  --silverSonic: #757780;
}

/* http://meyerweb.com/eric/tools/css/reset/
   v4.0 | 20180602
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
main,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section {
  display: block;
}

/* HTML5 hidden-attribute fix for newer browsers */
*[hidden] {
  display: none;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html,
body {
  width: 100%;
  height: 100%;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

img {
  display: block;
}

/* ----------------------------- SF Pro Display ----------------------------- */
@font-face {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 600;
  src: url("../../assets/fonts/SF-Pro-Display/SF-Pro-Display-Regular.ttf");
}
@font-face {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 500;
  src: url("../../assets/fonts/SF-Pro-Display/SF-Pro-Display-Medium.ttf");
}
@font-face {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 900;
  src: url("../../assets/fonts/SF-Pro-Display/SF-Pro-Display-Bold.ttf");
}
/* ----------------------------- Averta CY ----------------------------- */
@font-face {
  font-family: "Averta CY";
  font-style: normal;
  font-weight: 400;
  src: url("../../assets/fonts/Averta-CY/Averta-CY-Regular.ttf");
}
@font-face {
  font-family: "Averta CY";
  font-style: normal;
  font-weight: 600;
  src: url("../../assets/fonts/Averta-CY/Averta-Cyrillic-SemiBold.otf");
}
@font-face {
  font-family: "Averta CY";
  font-style: normal;
  font-weight: 700;
  src: url("../../assets/fonts/Averta-CY/Averta-CY-Bold.otf");
}
/* ----------------------------- Plus Jakarta Sans ----------------------------- */
@font-face {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 400;
  src: url("../../assets/fonts/Plus Jakarta Sans/Plus-Jakarta-Sans-Regular.ttf");
}
@font-face {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 500;
  src: url("../../assets/fonts/Plus Jakarta Sans/Plus-Jakarta-Sans-Medium.ttf");
}
@font-face {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 600;
  src: url("../../assets/fonts/Plus Jakarta Sans/Plus-Jakarta-Sans-SemiBold.ttf");
}
/*--------------------------- Work Sans -------------------------------*/
@font-face {
  font-family: "Work Sans Bold";
  font-style: normal;
  font-weight: 700;
  src: url("../../assets/fonts/Work Sans/WorkSans-Bold.ttf");
}
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/Work Sans/WorkSans-Medium.ttf");
}
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/Work Sans/WorkSans-Regular.ttf");
}
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/Work Sans/WorkSans-SemiBold.ttf");
}
/*---------------------sf mono---------------------------------------*/
@font-face {
  font-family: "SF Mono";
  font-style: normal;
  font-weight: 700;
  src: url("../../assets/fonts/SFMono/SFMono-Medium.otf");
}
@font-face {
  font-family: "SF Mono";
  font-style: normal;
  font-weight: 600;
  src: url("../../assets/fonts/SFMono/SFMono-Medium.otf");
}
@font-face {
  font-family: "SF Mono";
  font-style: normal;
  font-weight: 500;
  src: url("../../assets/fonts/SFMono/SFMono-Bold.otf");
}
@font-face {
  font-family: "SF Mono";
  font-style: normal;
  font-weight: 700;
  src: url("../../assets/fonts/SFMono/SFMono-Bold.otf");
}
@font-face {
  font-family: "SF Mono";
  font-style: normal;
  font-weight: 900;
  src: url("../../assets/fonts/SFMono/SFMono-Heavy.otf");
}
/**
 * Basic typography style for text
 */
body {
  color: rgba(255, 255, 255, 0.6);
  font: normal 100%/1.2 "Averta CY", "Arial", sans-serif;
  letter-spacing: -0.5px;
}

.title {
  font-family: "Averta CY", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  color: #ffffff;
}

.title--size-20 {
  font-size: 20px !important;
}

.title--grey {
  color: rgba(255, 255, 255, 0.4);
}

.title--metal {
  color: #b7b8be;
}

.title--black {
  color: #001011;
}

.title-silver {
  color: #757780 !important;
}

.text--centered {
  text-align: center;
}

.text {
  font-family: "Averta CY", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.title-expose {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  color: #001011;
}

.title-expose--blue {
  color: #2d00f7;
}

.title-expose--size-14 {
  font-size: 14px;
  line-height: 24px;
}

.text-expose {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: #757780;
}

.text-onboarding {
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #001011;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}

.text-expose--size-13 {
  font-size: 13px;
  line-height: 18px;
}

.text-expose--size-16 {
  font-size: 16px;
  line-height: 26px;
}

.title-expose--size-32 {
  font-size: 32px;
  line-height: 48px;
}

.title-expose--size-40 {
  font-size: 40px;
  line-height: 56px;
}

.text-expose--greyMetal {
  color: #b7b8be;
}

.text-expose--blackRich {
  color: #001011;
}

.text-expose--size-26 {
  font-family: Plus Jakarta Sans;
  font-size: 26px;
  line-height: 36px;
}

.text-expose--color-26 {
  color: var(--neutrals-rich-black, #001011);
  text-align: center;
}

.text-expose--size-14 {
  justify-content: center;
  display: flex;
  font-size: 14px;
  line-height: 24px;
}

.container-mvp {
  max-width: 360px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
}

.container-settings {
  width: 300px;
  padding: 40px;
}
@media (max-width: 480px) {
  .container-settings {
    width: 160px;
    padding: 10px;
  }
}
.container-settings.container-settings--document-settings {
  max-width: 313px;
  width: calc(18.5vw - 40px);
  height: 100%;
  min-height: 80vh;
}

.container-expose {
  width: 100%;
  height: 100%;
}

.container-expose-step {
  max-width: 940px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}

.container-onboarding {
  max-width: 740px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

html {
  --mat-option-selected-state-label-text-color: #3f51b5;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-accent {
  --mat-option-selected-state-label-text-color: #ff4081;
}

.mat-warn {
  --mat-option-selected-state-label-text-color: #f44336;
}

html {
  --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87);
}

.mat-pseudo-checkbox-full {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #3f51b5;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #3f51b5;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #ff4081;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #ff4081;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #ff4081;
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #ff4081;
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #f44336;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #f44336;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #b0b0b0;
}
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-mdc-card {
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #3f51b5;
  --mdc-linear-progress-track-color: rgba(63, 81, 181, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-color: rgba(63, 81, 181, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(63, 81, 181, 0.25));
}
@media (forced-colors: active) {
  .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(63, 81, 181, 0.25)'/%3E%3C/svg%3E");
  }
}
.mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(63, 81, 181, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(63, 81, 181, 0.25));
}
.mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #ff4081;
  --mdc-linear-progress-track-color: rgba(255, 64, 129, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-color: rgba(255, 64, 129, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(255, 64, 129, 0.25));
}
@media (forced-colors: active) {
  .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(255, 64, 129, 0.25)'/%3E%3C/svg%3E");
  }
}
.mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(255, 64, 129, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(255, 64, 129, 0.25));
}
.mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #f44336;
  --mdc-linear-progress-track-color: rgba(244, 67, 54, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-color: rgba(244, 67, 54, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(244, 67, 54, 0.25));
}
@media (forced-colors: active) {
  .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(244, 67, 54, 0.25)'/%3E%3C/svg%3E");
  }
}
.mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(244, 67, 54, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(244, 67, 54, 0.25));
}
.mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
}

html {
  --mdc-filled-text-field-caret-color: #3f51b5;
  --mdc-filled-text-field-focus-active-indicator-color: #3f51b5;
  --mdc-filled-text-field-focus-label-text-color: rgba(63, 81, 181, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-focus-label-text-color: #f44336;
  --mdc-filled-text-field-error-label-text-color: #f44336;
  --mdc-filled-text-field-error-caret-color: #f44336;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-focus-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-hover-active-indicator-color: #f44336;
  --mdc-outlined-text-field-caret-color: #3f51b5;
  --mdc-outlined-text-field-focus-outline-color: #3f51b5;
  --mdc-outlined-text-field-focus-label-text-color: rgba(63, 81, 181, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: #f44336;
  --mdc-outlined-text-field-error-focus-label-text-color: #f44336;
  --mdc-outlined-text-field-error-label-text-color: #f44336;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #f44336;
  --mdc-outlined-text-field-error-hover-outline-color: #f44336;
  --mdc-outlined-text-field-error-outline-color: #f44336;
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #f44336);
}

.mat-mdc-form-field-subscript-wrapper,
.mat-mdc-form-field-bottom-align::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mat-form-field-subscript-text-font);
  line-height: var(--mat-form-field-subscript-text-line-height);
  font-size: var(--mat-form-field-subscript-text-size);
  letter-spacing: var(--mat-form-field-subscript-text-tracking);
  font-weight: var(--mat-form-field-subscript-text-weight);
}

.mat-mdc-form-field-focus-overlay {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.04;
}

.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.12;
}

.mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(63, 81, 181, 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(255, 64, 129, 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(244, 67, 54, 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #ff4081;
  --mdc-filled-text-field-focus-active-indicator-color: #ff4081;
  --mdc-filled-text-field-focus-label-text-color: rgba(255, 64, 129, 0.87);
  --mdc-outlined-text-field-caret-color: #ff4081;
  --mdc-outlined-text-field-focus-outline-color: #ff4081;
  --mdc-outlined-text-field-focus-label-text-color: rgba(255, 64, 129, 0.87);
}

.mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #f44336;
  --mdc-filled-text-field-focus-active-indicator-color: #f44336;
  --mdc-filled-text-field-focus-label-text-color: rgba(244, 67, 54, 0.87);
  --mdc-outlined-text-field-caret-color: #f44336;
  --mdc-outlined-text-field-focus-outline-color: #f44336;
  --mdc-outlined-text-field-focus-label-text-color: rgba(244, 67, 54, 0.87);
}

.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}

[dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}

.mat-mdc-form-field-infix {
  min-height: 56px;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 28px;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -34.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 24px;
  padding-bottom: 8px;
}

.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}

html {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(63, 81, 181, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}
html .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(255, 64, 129, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}
html .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(244, 67, 54, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}

html {
  --mat-autocomplete-background-color: white;
}

.mat-mdc-dialog-container {
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}

.mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #3f51b5;
  --mdc-chip-elevated-disabled-container-color: #3f51b5;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #ff4081;
  --mdc-chip-elevated-disabled-container-color: #ff4081;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #f44336;
  --mdc-chip-elevated-disabled-container-color: #f44336;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 32px;
}

.mat-mdc-slide-toggle {
  --mdc-switch-selected-focus-state-layer-color: #3949ab;
  --mdc-switch-selected-handle-color: #3949ab;
  --mdc-switch-selected-hover-state-layer-color: #3949ab;
  --mdc-switch-selected-pressed-state-layer-color: #3949ab;
  --mdc-switch-selected-focus-handle-color: #1a237e;
  --mdc-switch-selected-hover-handle-color: #1a237e;
  --mdc-switch-selected-pressed-handle-color: #1a237e;
  --mdc-switch-selected-focus-track-color: #7986cb;
  --mdc-switch-selected-hover-track-color: #7986cb;
  --mdc-switch-selected-pressed-track-color: #7986cb;
  --mdc-switch-selected-track-color: #7986cb;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
}
.mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #d81b60;
  --mdc-switch-selected-handle-color: #d81b60;
  --mdc-switch-selected-hover-state-layer-color: #d81b60;
  --mdc-switch-selected-pressed-state-layer-color: #d81b60;
  --mdc-switch-selected-focus-handle-color: #880e4f;
  --mdc-switch-selected-hover-handle-color: #880e4f;
  --mdc-switch-selected-pressed-handle-color: #880e4f;
  --mdc-switch-selected-focus-track-color: #f06292;
  --mdc-switch-selected-hover-track-color: #f06292;
  --mdc-switch-selected-pressed-track-color: #f06292;
  --mdc-switch-selected-track-color: #f06292;
}
.mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #e53935;
  --mdc-switch-selected-handle-color: #e53935;
  --mdc-switch-selected-hover-state-layer-color: #e53935;
  --mdc-switch-selected-pressed-state-layer-color: #e53935;
  --mdc-switch-selected-focus-handle-color: #b71c1c;
  --mdc-switch-selected-hover-handle-color: #b71c1c;
  --mdc-switch-selected-pressed-handle-color: #b71c1c;
  --mdc-switch-selected-focus-track-color: #e57373;
  --mdc-switch-selected-hover-track-color: #e57373;
  --mdc-switch-selected-pressed-track-color: #e57373;
  --mdc-switch-selected-track-color: #e57373;
}

.mat-mdc-slide-toggle {
  --mdc-switch-state-layer-size: 48px;
}

.mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #3f51b5;
  --mdc-radio-selected-hover-icon-color: #3f51b5;
  --mdc-radio-selected-icon-color: #3f51b5;
  --mdc-radio-selected-pressed-icon-color: #3f51b5;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #3f51b5;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #ff4081;
  --mdc-radio-selected-hover-icon-color: #ff4081;
  --mdc-radio-selected-icon-color: #ff4081;
  --mdc-radio-selected-pressed-icon-color: #ff4081;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #ff4081;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #f44336;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-radio-button .mdc-radio {
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-slider {
  --mdc-slider-label-container-color: black;
  --mdc-slider-label-label-text-color: white;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-mdc-slider-value-indicator-opacity: 0.6;
}
.mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #3f51b5;
  --mdc-slider-focus-handle-color: #3f51b5;
  --mdc-slider-hover-handle-color: #3f51b5;
  --mdc-slider-active-track-color: #3f51b5;
  --mdc-slider-inactive-track-color: #3f51b5;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #3f51b5;
  --mat-mdc-slider-ripple-color: #3f51b5;
  --mat-mdc-slider-hover-ripple-color: rgba(63, 81, 181, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(63, 81, 181, 0.2);
}
.mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #ff4081;
  --mdc-slider-focus-handle-color: #ff4081;
  --mdc-slider-hover-handle-color: #ff4081;
  --mdc-slider-active-track-color: #ff4081;
  --mdc-slider-inactive-track-color: #ff4081;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #ff4081;
  --mat-mdc-slider-ripple-color: #ff4081;
  --mat-mdc-slider-hover-ripple-color: rgba(255, 64, 129, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(255, 64, 129, 0.2);
}
.mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #f44336;
  --mdc-slider-focus-handle-color: #f44336;
  --mdc-slider-hover-handle-color: #f44336;
  --mdc-slider-active-track-color: #f44336;
  --mdc-slider-inactive-track-color: #f44336;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #f44336;
  --mat-mdc-slider-ripple-color: #f44336;
  --mat-mdc-slider-hover-ripple-color: rgba(244, 67, 54, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(244, 67, 54, 0.2);
}

html {
  --mat-menu-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-icon-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
}

.mat-mdc-list-base {
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #3f51b5;
  --mdc-radio-selected-hover-icon-color: #3f51b5;
  --mdc-radio-selected-icon-color: #3f51b5;
  --mdc-radio-selected-pressed-icon-color: #3f51b5;
}

.mat-accent .mdc-list-item__start,
.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #ff4081;
  --mdc-radio-selected-hover-icon-color: #ff4081;
  --mdc-radio-selected-icon-color: #ff4081;
  --mdc-radio-selected-pressed-icon-color: #ff4081;
}

.mat-warn .mdc-list-item__start,
.mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
}

.mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #3f51b5;
  --mdc-checkbox-selected-hover-icon-color: #3f51b5;
  --mdc-checkbox-selected-icon-color: #3f51b5;
  --mdc-checkbox-selected-pressed-icon-color: #3f51b5;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #3f51b5;
  --mdc-checkbox-selected-hover-state-layer-color: #3f51b5;
  --mdc-checkbox-selected-pressed-state-layer-color: #3f51b5;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #ff4081;
  --mdc-checkbox-selected-hover-icon-color: #ff4081;
  --mdc-checkbox-selected-icon-color: #ff4081;
  --mdc-checkbox-selected-pressed-icon-color: #ff4081;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ff4081;
  --mdc-checkbox-selected-hover-state-layer-color: #ff4081;
  --mdc-checkbox-selected-pressed-state-layer-color: #ff4081;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #3f51b5;
}
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #3f51b5;
}

.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}

.mat-mdc-list-base {
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-two-line-container-height: 64px;
  --mdc-list-list-item-three-line-container-height: 88px;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 56px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 72px;
}

html {
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-paginator-container-size: 56px;
}

.mat-mdc-paginator .mat-mdc-form-field-infix {
  min-height: 40px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 20px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -26.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
  display: none;
}

.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #3f51b5;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #3f51b5;
  --mat-tab-header-active-ripple-color: #3f51b5;
  --mat-tab-header-inactive-ripple-color: #3f51b5;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #3f51b5;
  --mat-tab-header-active-hover-label-text-color: #3f51b5;
  --mat-tab-header-active-focus-indicator-color: #3f51b5;
  --mat-tab-header-active-hover-indicator-color: #3f51b5;
}
.mat-mdc-tab-group.mat-accent, .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #ff4081;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #ff4081;
  --mat-tab-header-active-ripple-color: #ff4081;
  --mat-tab-header-inactive-ripple-color: #ff4081;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #ff4081;
  --mat-tab-header-active-hover-label-text-color: #ff4081;
  --mat-tab-header-active-focus-indicator-color: #ff4081;
  --mat-tab-header-active-hover-indicator-color: #ff4081;
}
.mat-mdc-tab-group.mat-warn, .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #f44336;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #f44336;
  --mat-tab-header-active-ripple-color: #f44336;
  --mat-tab-header-inactive-ripple-color: #f44336;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #f44336;
  --mat-tab-header-active-hover-label-text-color: #f44336;
  --mat-tab-header-active-focus-indicator-color: #f44336;
  --mat-tab-header-active-hover-indicator-color: #f44336;
}
.mat-mdc-tab-group.mat-background-primary, .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #3f51b5;
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-accent, .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #ff4081;
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-warn, .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #f44336;
  --mat-tab-header-with-background-foreground-color: white;
}

.mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 48px;
}

html {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #ff4081;
  --mdc-checkbox-selected-hover-icon-color: #ff4081;
  --mdc-checkbox-selected-icon-color: #ff4081;
  --mdc-checkbox-selected-pressed-icon-color: #ff4081;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ff4081;
  --mdc-checkbox-selected-hover-state-layer-color: #ff4081;
  --mdc-checkbox-selected-pressed-state-layer-color: #ff4081;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #3f51b5;
  --mdc-checkbox-selected-hover-icon-color: #3f51b5;
  --mdc-checkbox-selected-icon-color: #3f51b5;
  --mdc-checkbox-selected-pressed-icon-color: #3f51b5;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #3f51b5;
  --mdc-checkbox-selected-hover-state-layer-color: #3f51b5;
  --mdc-checkbox-selected-pressed-state-layer-color: #3f51b5;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-checkbox.mat-mdc-checkbox-disabled label {
  color: rgba(0, 0, 0, 0.38);
}

html {
  --mdc-checkbox-state-layer-size: 40px;
}

.mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}
.mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #3f51b5;
}
.mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #ff4081;
}
.mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #f44336;
}
.mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}
.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #3f51b5;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #ff4081;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #f44336;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #000;
}
.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #3f51b5;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #ff4081;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #f44336;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation: 0;
}

.mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}
.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #3f51b5;
}
.mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #ff4081;
}
.mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #f44336;
}
.mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
}

.mat-mdc-button, .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-button.mat-primary, .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #3f51b5;
  --mat-mdc-button-ripple-color: rgba(63, 81, 181, 0.1);
}
.mat-mdc-button.mat-accent, .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #ff4081;
  --mat-mdc-button-ripple-color: rgba(255, 64, 129, 0.1);
}
.mat-mdc-button.mat-warn, .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #f44336;
  --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1);
}

.mat-mdc-raised-button, .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-raised-button.mat-primary, .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-accent, .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-warn, .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-button.mat-mdc-button-base,
.mat-mdc-raised-button.mat-mdc-button-base,
.mat-mdc-unelevated-button.mat-mdc-button-base,
.mat-mdc-outlined-button.mat-mdc-button-base {
  height: 36px;
}

.mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mdc-icon-button-icon-color: inherit;
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #6200ee;
  --mat-mdc-button-ripple-color: rgba(98, 0, 238, 0.1);
}
.mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #018786;
  --mat-mdc-button-ripple-color: rgba(1, 135, 134, 0.1);
}
.mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #b00020;
  --mat-mdc-button-ripple-color: rgba(176, 0, 32, 0.1);
}
.mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #3f51b5;
  --mat-mdc-button-persistent-ripple-color: #3f51b5;
  --mat-mdc-button-ripple-color: rgba(63, 81, 181, 0.1);
}
.mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #ff4081;
  --mat-mdc-button-persistent-ripple-color: #ff4081;
  --mat-mdc-button-ripple-color: rgba(255, 64, 129, 0.1);
}
.mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #f44336;
  --mat-mdc-button-persistent-ripple-color: #f44336;
  --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 48px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 12px;
}

.mat-mdc-fab,
.mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before,
.mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before,
.mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-fab.mat-primary,
.mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-fab.mat-accent,
.mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-fab.mat-warn,
.mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-fab[disabled][disabled],
.mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(0, 0, 0, 0.12);
  --mdc-fab-icon-color: rgba(0, 0, 0, 0.38);
  --mat-mdc-fab-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-fab.mat-unthemed,
.mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: white;
  --mdc-fab-icon-color: black;
  --mat-mdc-fab-color: #000;
}
.mat-mdc-fab.mat-primary,
.mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #3f51b5;
  --mdc-fab-icon-color: white;
  --mat-mdc-fab-color: #fff;
}
.mat-mdc-fab.mat-accent,
.mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #ff4081;
  --mdc-fab-icon-color: white;
  --mat-mdc-fab-color: #fff;
}
.mat-mdc-fab.mat-warn,
.mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #f44336;
  --mdc-fab-icon-color: white;
  --mat-mdc-fab-color: #fff;
}

.mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #ff4081;
}

html {
  --mat-table-background-color: white;
  --mat-table-header-headline-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-table-header-container-height: 56px;
  --mat-table-footer-container-height: 52px;
  --mat-table-row-item-container-height: 52px;
}

.mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #3f51b5;
}
.mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #ff4081;
}
.mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #f44336;
}

.mat-badge {
  position: relative;
}
.mat-badge.mat-badge {
  overflow: visible;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
  background-color: var(--mat-badge-background-color);
  color: var(--mat-badge-text-color);
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mat-badge-text-font, Roboto, sans-serif);
  font-size: 12px;
  /* @alternate */
  font-size: var(--mat-badge-text-size, 12px);
  font-weight: 600;
  /* @alternate */
  font-weight: var(--mat-badge-text-weight, 600);
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-disabled .mat-badge-content {
  background-color: var(--mat-badge-disabled-state-background-color);
  color: var(--mat-badge-disabled-state-text-color);
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
  font-size: 9px;
  /* @alternate */
  font-size: var(--mat-badge-small-size-text-size, 9px);
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
  font-size: 24px;
  /* @alternate */
  font-size: var(--mat-badge-large-size-text-size, 24px);
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

html {
  --mat-badge-background-color: #3f51b5;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #b9b9b9;
  --mat-badge-disabled-state-text-color: rgba(0, 0, 0, 0.38);
}

.mat-badge-accent {
  --mat-badge-background-color: #ff4081;
  --mat-badge-text-color: white;
}

.mat-badge-warn {
  --mat-badge-background-color: #f44336;
  --mat-badge-text-color: white;
}

html {
  --mat-bottom-sheet-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-bottom-sheet-container-background-color: white;
}

html {
  --mat-legacy-button-toggle-text-color: rgba(0, 0, 0, 0.38);
  --mat-legacy-button-toggle-state-layer-color: rgba(0, 0, 0, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.54);
  --mat-legacy-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-legacy-button-toggle-disabled-state-background-color: #eeeeee;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: black;
  --mat-standard-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-standard-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-divider-color: #e0e0e0;
}

html {
  --mat-standard-button-toggle-height: 48px;
}

html {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #3f51b5;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(63, 81, 181, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(63, 81, 181, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(63, 81, 181, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #3f51b5;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(63, 81, 181, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #ff4081;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(255, 64, 129, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(255, 64, 129, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(255, 64, 129, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(255, 64, 129, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #f44336;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(244, 67, 54, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(244, 67, 54, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}

.mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #ff4081;
}
.mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #f44336;
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}

html {
  --mat-divider-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-expansion-header-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-header-description-color: rgba(0, 0, 0, 0.54);
  --mat-expansion-header-indicator-color: rgba(0, 0, 0, 0.54);
}

html {
  --mat-expansion-header-collapsed-state-height: 48px;
  --mat-expansion-header-expanded-state-height: 64px;
}

html {
  --mat-icon-color: inherit;
}

.mat-icon.mat-primary {
  --mat-icon-color: #3f51b5;
}
.mat-icon.mat-accent {
  --mat-icon-color: #ff4081;
}
.mat-icon.mat-warn {
  --mat-icon-color: #f44336;
}

html {
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-content-background-color: #fafafa;
  --mat-sidenav-content-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
}

html {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #3f51b5;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #3f51b5;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #3f51b5;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-selected-state-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-error-state-label-text-color: #f44336;
  --mat-stepper-header-icon-background-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-error-state-icon-foreground-color: #f44336;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
html .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #ff4081;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #ff4081;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #ff4081;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
html .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #f44336;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #f44336;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #f44336;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}

html {
  --mat-stepper-header-height: 72px;
}

.mat-sort-header-arrow {
  color: #757575;
}

html {
  --mat-toolbar-container-background-color: whitesmoke;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #3f51b5;
  --mat-toolbar-container-text-color: white;
}
.mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #ff4081;
  --mat-toolbar-container-text-color: white;
}
.mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #f44336;
  --mat-toolbar-container-text-color: white;
}

html {
  --mat-toolbar-standard-height: 64px;
  --mat-toolbar-mobile-height: 56px;
}

.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.mat-tree-node {
  min-height: 48px;
}

/* ---------------------------------- Base ---------------------------------- */
/* ---------------------------------- Text ---------------------------------- */
/* --------------------------------- Background -------------------------------- */
/* --------------------------------- Buttons -------------------------------- */
/* ---------------------------------- Cards --------------------------------- */
/* --------------------------------- Inputs --------------------------------- */
/* ---------------------------------- Utils --------------------------------- */
:root {
  --silverSonic: #757780;
}

/* ------------------------------- button-mvp ------------------------------- */
.button-mvp {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 49px;
  padding: 15px 25px;
  font-family: "Averta CY", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  color: #ffffff;
  border-radius: 1000px;
  border: none;
  background: linear-gradient(265deg, #ff81ff 2.96%, #a93aff 53.79%), linear-gradient(86deg, #ffedad 46.43%, #ffffe5 100%);
  outline: none;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  overflow: hidden;
}

.button-mvp--pink {
  background: rgba(255, 129, 255, 0.2);
}

.button-mvp:hover,
.button-mvp:focus-within {
  transform: scale(1.03);
  border-color: rgba(255, 255, 255, 0.6);
}

.button-mvp--pink:hover {
  background: rgba(255, 129, 255, 0.3) !important;
}

.button-mvp:hover {
  background: linear-gradient(265deg, rgba(255, 129, 255, 0.9) 2.96%, rgba(169, 58, 255, 0.9) 53.79%), linear-gradient(86deg, rgba(255, 237, 173, 0.9) 46.43%, rgba(255, 255, 229, 0.9) 100%);
}

.button-mvp::before {
  content: "";
  position: absolute;
  width: 100px;
  height: 100%;
  background-image: linear-gradient(120deg, rgba(255, 255, 255, 0) 30%, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0) 70%);
  top: 0;
  left: -100px;
  opacity: 0.6;
}

@keyframes shine {
  0% {
    left: -100px;
  }
  60% {
    left: 100%;
  }
  to {
    left: 100%;
  }
}
/* ------------------------------- button-type ------------------------------- */
.button-type {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 10px 25px;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #757780;
  border-top: 1px solid #b7b8be;
  border-bottom: 1px solid #b7b8be;
  border-right: none;
  border-left: none;
  background: transparent;
  outline: none;
  transition: all 0.1s ease-in-out;
  cursor: pointer;
}
.button-type span {
  text-align: center;
}

.button-type--first {
  border-left: 1px solid #b7b8be;
  border-radius: 3px 0 0 3px;
}

.button-type--first:hover {
  border-left: 1px solid #2d00f7;
}

.button-type--last {
  border-right: 1px solid #b7b8be;
  border-radius: 0 3px 3px 0;
}

.button-type--last:hover {
  border-right: 1px solid #2d00f7;
}

.button-type--border-left {
  border-left: 1px solid #b7b8be;
}

.button-type--border-left:hover {
  border-left: 1px solid #2d00f7;
}

.button-type:hover {
  color: #ffffff;
  border-top: 1px solid #2d00f7;
  border-bottom: 1px solid #2d00f7;
  background: #2d00f7;
  transition: all 0.1s ease-in-out;
}

/* ------------------------------- button-expose ------------------------------- */
.button-expose {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: auto;
  height: 48px;
  padding: 12px 24px 12px 19px;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  color: #fdfdfd;
  background: #2d00f7;
  border-radius: 4px;
  border: none;
  outline: none;
  cursor: pointer;
}

/* ------------------------------- button-skip ------------------------------- */
.button-skip {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 44px;
  padding: 13px 16px 14px 16px;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  color: #001011;
  background: transparent;
  border-radius: 4px;
  appearance: none;
  border: 1px solid #b7b8be;
  cursor: pointer;
}

.button-onboarding {
  padding: 12px 24px;
  max-width: 700px;
  width: 100%;
  color: #fdfdfd;
  background: #2d00f7;
}
.button-onboarding.disabled {
  pointer-events: none;
  color: #b7b8be !important;
  background-color: #F3F5F6;
}

.button-expose--disabled {
  color: #b7b8be;
  background: #ebedef;
  cursor: not-allowed;
}
.button-expose--disabled mat-icon svg path {
  fill: #b7b8be;
}

.button-expose--grey {
  padding: 24px;
  color: #001011;
  background: #ebedef;
}

.button-with-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 48px;
  padding: 12px 24px 12px 19px;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: #fdfdfd;
  background-color: #2d00f7;
  outline: none;
  border-radius: 4px;
  border: none;
  transition: all 0.1s ease-in-out;
  cursor: pointer;
}
@media (max-width: 480px) {
  .button-with-icon {
    width: 216px;
  }
}

.button-dashboard {
  width: 232px;
}
.button-dashboard.disabled {
  pointer-events: none;
  color: #b7b8be !important;
  background-color: #F3F5F6;
}

.button-with-icon--gray {
  background-color: #ebedef;
  color: #001011;
}

.button-secondary {
  width: 94px;
  height: 48px;
  display: inline-flex;
  padding: 12px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: #ebedef;
  margin-right: 16px;
  border: none;
  cursor: pointer;
}
.button-secondary:hover {
  background-color: rgba(235, 237, 239, 0.9);
}

.summary-page_gen-btn {
  color: var(--neutrals-snow-white, #fdfdfd);
  text-align: center;
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  display: inline-flex;
  padding: 12px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: var(--primary-total-blue, #2d00f7);
  cursor: pointer;
}
@media (max-width: 480px) {
  .summary-page_gen-btn {
    display: flex;
    width: 100%;
    max-width: 320px;
    padding: 12px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 768px) {
  .summary-page_gen-btn {
    display: flex;
    width: 320px;
    padding: 12px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

/* ------------------------------- form-mvp ------------------------------- */
.form-mvp {
  display: flex;
  height: 49px;
  padding: 15px 30px;
  border-radius: 1000px;
  background: rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease-in-out;
}
.form-mvp input {
  width: 100%;
  background: none;
  border: none;
  outline: none;
}
.form-mvp input[type=text] {
  font-family: "Averta CY", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  text-align: center;
  color: #ffffff;
}
.form-mvp input:-webkit-autofill,
.form-mvp input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}
.form-mvp input[type=text]::placeholder {
  font-family: "Averta CY", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  color: rgba(255, 255, 255, 0.4);
}
.form-mvp.active {
  background: rgba(255, 255, 255, 0.2);
}

.form-mvp:hover,
.form-mvp:active,
.form-mvp:focus-within {
  background: rgba(255, 255, 255, 0.2);
  transition: all 0.3s ease-in-out;
}

/* ------------------------------- form-group ------------------------------- */
.form-group-expose {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}
.form-group-expose .form-group-expose__error {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  color: #dd2e16;
}

.form-group-onboarding {
  align-items: flex-start;
}

/* ------------------------------- form-expose ------------------------------- */
.form-expose--error {
  border: 1px solid #dd2e16 !important;
}

.form-expose {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  height: 50px;
  padding: 13px 16px;
  border-radius: 4px;
  border: 1px solid #b7b8be;
  background: transparent;
}
.form-expose.error {
  border: 1px solid #DD2E16;
}
.form-expose input {
  width: 100%;
  background: none;
  border: none;
  outline: none;
}
.form-expose input[type=text] {
  padding: 0;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #001011;
}
.form-expose input[type=text]::placeholder {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #757780;
}
.form-expose input:-webkit-autofill,
.form-expose input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}
.form-expose .form-expose__btn {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 8px;
}
.form-expose .form-expose__btn .form-expose__remove {
  cursor: pointer;
}
.form-expose .form-expose__btn .form-expose__add {
  cursor: pointer;
}
.form-expose .form-expose__checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  border: 2px solid #757780;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.form-expose .form-expose__icon {
  display: block;
  opacity: 0;
  user-select: none;
  transition: all 0.2s ease-in-out;
}
.form-expose .form-expose__active {
  border: 2px solid #2d00f7;
  transition: all 0.2s ease-in-out;
}
.form-expose .form-expose__active img {
  opacity: 1;
}
.form-expose .form-expose__mat-icon {
  display: flex;
  max-width: 30px;
  width: 100%;
  max-height: 14px;
  height: 100%;
}
.form-expose .form-expose__remove {
  cursor: pointer;
}
.form-expose .form-expose__add {
  cursor: pointer;
}

.form-expose__zoom {
  height: 40px;
  background: #F3F5F6;
}

.form-expose--tag {
  min-height: 50px;
  height: auto;
  padding: 5px 8px;
}

.form-expose--tag-small {
  min-height: 50px;
  height: auto;
  padding: 8px;
}

/* ------------------------------- others style ------------------------------- */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  white-space: nowrap;
  border: 0;
  overflow: hidden;
}

/* ------------------------------- card-mvp ------------------------------- */
.card-mvp {
  border-radius: 24px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(40px);
  overflow: hidden;
}
.card-mvp .card-mvp__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  padding: 40px;
  overflow: hidden;
}
.card-mvp .card-mvp__wrapper--mvp-5 {
  padding-bottom: 10px;
}
.card-mvp .card-mvp__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.card-mvp .card-mvp__content--left {
  align-items: flex-start;
}
.card-mvp .card-mvp__icon {
  height: 48px;
}
.card-mvp .card-mvp__title {
  text-align: center;
}
.card-mvp .card-mvp__location {
  color: #ffffff;
  line-height: 120%;
  margin: 0;
  display: flex;
  justify-content: center;
  text-align: center;
}
.card-mvp .card-mvp__location input,
.card-mvp .card-mvp__location textarea {
  font-family: "Averta CY", sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  font-size: 20px;
  color: #ffffff;
  margin: 0;
  display: flex;
  justify-content: center;
  text-align: center;
  background-color: transparent;
  width: 200px;
  height: auto;
  min-height: 96px;
  outline: none;
  border-style: none;
  resize: none;
  overflow-y: hidden;
}
.card-mvp .card-mvp__title--left {
  text-align: left;
}
.card-mvp .card-mvp__title--sell {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
}
.card-mvp .card-mvp__title--sell .active {
  text-decoration: underline;
  color: #ff81ff;
}
.card-mvp .card-mvp__title--sell a {
  color: #ffffff;
  transition: all 0.2s ease-in-out;
}
.card-mvp .card-mvp__title--sell a:hover {
  text-decoration: underline;
  color: #ff81ff;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.card-mvp .card-mvp__text {
  text-align: center;
}
.card-mvp .card-mvp__text--left {
  text-align: left;
}
.card-mvp .card-mvp__button-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}
.card-mvp .card-mvp__gallery {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 7px;
  margin: 0 -30px;
}
.card-mvp .card-mvp__gallery img {
  width: 156.5px;
  height: 160.5px;
  border-radius: 6px;
  object-fit: cover;
}
.card-mvp .card-mvp__gallery img:first-child {
  width: 320px;
  height: 292px;
  border-radius: 13px;
  object-fit: cover;
}
.card-mvp .card-mvp__gallery--two img {
  width: 320px;
  height: 292px;
  border-radius: 13px;
  object-fit: cover;
}

.card-mvp--mvp-5 {
  padding-bottom: 10px;
}

.card-expose {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.card-expose .document-page__btns-sidebar {
  position: fixed;
  right: 40px;
  bottom: 74px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 768px) {
  .card-expose .document-page__btns-sidebar {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #ffffff;
  }
}
.card-expose .card-expose__control-btns {
  position: absolute;
  top: 40px;
  left: 50%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  gap: 16px;
}
@media (max-width: 1040px) {
  .card-expose .card-expose__control-btns {
    left: 30%;
  }
}
@media (max-width: 480px) {
  .card-expose .card-expose__control-btns {
    gap: 10px;
    top: 20px;
  }
}
.card-expose .card-expose__pages-btns {
  position: absolute;
  top: 40px;
  left: calc(100% - 313px - 96px);
  display: flex;
  flex-direction: row;
  gap: 16px;
}
@media (max-width: 1228px) {
  .card-expose .card-expose__pages-btns {
    left: 70%;
  }
}
.card-expose .card-expose__control-select {
  width: 89px;
  height: 40px;
}
.card-expose .card-expose__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-left: 300px;
  padding-top: 40px;
  padding-bottom: 40px;
}
@media (max-width: 768px) {
  .card-expose .card-expose__content {
    margin-left: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
}
.card-expose .card-expose__content.card-expose__content--with-sidebar {
  background-color: #F3F5F6;
  margin-top: 120px;
  margin-bottom: 74px;
  max-width: calc(100% - 270px - 313px);
}
@media (max-width: 1040px) {
  .card-expose .card-expose__content.card-expose__content--with-sidebar {
    max-width: 567px;
  }
}
@media (max-width: 768px) {
  .card-expose .card-expose__content.card-expose__content--with-sidebar {
    margin-top: 0px;
    max-width: 100%;
    min-width: 320px;
  }
}
.card-expose .card-expose__content.card-expose__content--no-data {
  background-color: #ffffff;
  height: 80vh;
}
.card-expose .card-expose__icon--inner-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  height: 446px;
  width: 500px;
}
@media (max-width: 480px) {
  .card-expose .card-expose__icon--inner-content {
    width: 220px;
  }
}

.card-settings {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.card-settings .card-settings__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}
.card-settings .card-settings__content--bottom-content {
  position: absolute;
  bottom: 40px;
}
.card-settings .card-settings__header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 40px;
  padding-left: 12px;
  width: 220px;
  cursor: pointer;
}
@media all and (max-width: 1300px) {
  .card-settings .card-settings__header {
    width: 161px;
  }
}
@media (max-width: 480px) {
  .card-settings .card-settings__header {
    width: 150px;
  }
}
.card-settings .settings-page__title {
  color: #001011;
}
.card-settings .settings-page__title.active {
  color: #2d00f7;
}
.card-settings .settings-page__title--gray {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  color: #757780;
}
.card-settings .settings-page__title--gray.active {
  color: #001011;
}
.card-settings .settings-page__menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  padding: 11px 12px;
  gap: 10px;
  cursor: pointer;
  width: 220px;
}
@media all and (max-width: 1300px) {
  .card-settings .settings-page__menu {
    width: 161px;
  }
}
@media (max-width: 480px) {
  .card-settings .settings-page__menu {
    width: 150px;
  }
}

.section-realty-images__card {
  border-radius: 4px;
  border: 1px dashed #b7b8be;
  background: #fdfdfd;
  width: 273px;
  height: 154px;
  position: relative;
}
@media all and (max-width: 1200px) {
  .section-realty-images__card {
    max-width: 300px;
    height: 180px;
  }
}
@media (max-width: 768px) {
  .section-realty-images__card {
    max-width: 320px;
    width: 100%;
    height: 180px;
  }
}
@media (max-width: 480px) {
  .section-realty-images__card.section-realty-images__card--first-card {
    height: 263px;
  }
}
.section-realty-images__card .section-realty-images__category {
  position: absolute;
  top: 8px;
  left: 8px;
  z-index: 100;
  height: 34px;
  background-color: #f5f5f5;
  color: #b7b8be;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
}
.section-realty-images__card .section-realty-images__image {
  z-index: 1;
}
.section-realty-images__card .section-realty-images__image input {
  z-index: 1;
  width: 273px;
  height: 154px;
}
.section-realty-images__card .section-realty-images__image input.disabled {
  pointer-events: none;
}
.section-realty-images__card .section-realty-images__image .avatar {
  display: flex;
  width: 273px;
  height: 154px;
  flex-shrink: 0;
  align-self: stretch;
  overflow: hidden;
  position: relative;
}
.section-realty-images__card .section-realty-images__image .avatar input {
  outline: none;
  border-radius: 12px;
  display: block;
  width: 273px;
  height: 154px;
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1000;
}
.section-realty-images__card .section-realty-images__image .avatar .avatar-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.section-realty-images__card .section-realty-images__image .avatar .avatar--placeholder {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.section-realty-images__card .section-realty-images__image .avatar .avatar--placeholder img {
  width: 32px;
  height: 32px;
  opacity: 0.6;
  margin-bottom: 12px;
}
.section-realty-images__card .section-realty-images__image .avatar .avatar--placeholder .image-text {
  font-family: Averta;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: rgba(0, 0, 0, 0.8);
}
.section-realty-images__card .section-realty-images__image .avatar .avatar--placeholder .image-text.cover {
  font-family: Averta;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.6);
}
@media all and (max-width: 1200px) {
  .section-realty-images__card .section-realty-images__image {
    width: 300px;
    height: 180px;
  }
  .section-realty-images__card .section-realty-images__image input {
    width: 300px;
    height: 180px;
  }
  .section-realty-images__card .section-realty-images__image .avatar {
    width: 300px;
    height: 180px;
  }
  .section-realty-images__card .section-realty-images__image .avatar input {
    width: 300px;
    height: 180px;
  }
}
@media all and (max-width: 768px) {
  .section-realty-images__card .section-realty-images__image {
    width: 320px;
    height: 180px;
  }
  .section-realty-images__card .section-realty-images__image input {
    width: 320px;
    height: 180px;
  }
  .section-realty-images__card .section-realty-images__image .avatar {
    width: 320px;
    height: 180px;
  }
  .section-realty-images__card .section-realty-images__image .avatar input {
    width: 320px;
    height: 180px;
  }
}
.section-realty-images__card .section-realty-images__image.section-realty-images__image--first-card {
  width: 320px;
  height: 263px;
}
.section-realty-images__card .section-realty-images__image.section-realty-images__image--first-card input {
  width: 320px;
  height: 263px;
}
.section-realty-images__card .section-realty-images__image.section-realty-images__image--first-card .avatar {
  width: 320px;
  height: 263px;
}
.section-realty-images__card .section-realty-images__image.section-realty-images__image--first-card .avatar input {
  width: 320px;
  height: 263px;
}
.section-realty-images__card .section-realty-images__image .section-realty-images__btn {
  margin-top: 14px;
  display: flex;
  width: 210px;
  height: 36px;
  padding: 12px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 4px;
  background: #ebedef;
  outline: none;
  border: none;
}
.section-realty-images__card .section-realty-images__image .section-realty-images__image--title {
  width: 210px;
  text-align: center;
  margin-top: 8px;
}

.modal-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
}
@media (max-width: 480px) {
  .modal-wrapper {
    max-width: 250px;
  }
}

.modal-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.modal-content {
  display: flex;
  width: 522px;
  align-items: flex-start;
  align-content: flex-start;
  gap: 8px;
  flex-wrap: wrap;
}
@media (max-width: 480px) {
  .modal-content {
    width: 250px;
  }
}

.modal-content__card {
  display: flex;
  padding: 8px;
  height: 34px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #B7B8BE;
  background: #FDFDFD;
  color: #757780;
  cursor: pointer;
}
.modal-content__card.active, .modal-content__card:hover {
  border-radius: 4px;
  background-color: #EBEDEF;
}

.modal-back {
  display: flex;
  flex-direction: row;
  gap: 12px;
}
.modal-back .modal-back__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 12px;
  width: 100%;
}
.modal-back .modal-back__icon img {
  width: 11px;
  height: 19px;
}
.modal-back .modal-back__step {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: rgba(255, 255, 255, 0.6);
}

/* ------------------------------- header-expose ------------------------------- */
.header-expose {
  display: none;
}
@media (max-width: 768px) {
  .header-expose {
    display: block;
  }
}
.header-expose .header-document__mobile {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}
.header-expose .header-document__control-btns {
  display: flex;
  flex-direction: row;
  gap: 16px;
}
.header-expose .header-expose__container {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  gap: 10px;
  padding: 20px 20px;
  border-bottom: 1px solid #ebedef;
}
.header-expose .header-expose__icon {
  cursor: pointer;
}
.header-expose .header-expose__icon mat-icon {
  display: flex;
}
.header-expose .header-expose__page {
  color: #757780;
}

/* ------------------------------- header-onboarding ------------------------------- */
.header-onboarding {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
/* ----------------------------- section-mvp ----------------------------- */
.section-mvp .section-mvp__card-mvp {
  width: 100%;
}
.section-mvp .section-mvp__button-wrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 20px 10px 40px 10px;
}
/* ----------------------------- section-step ----------------------------- */
.section-step .section-step__container {
  max-width: 900px;
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  gap: 16px;
  margin-bottom: 40px;
  text-align: center;
}
@media all and (max-width: 768px) {
  .section-step .mobile-container {
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    text-align: left;
  }
}
.section-step .section-step__container--no-data {
  background-color: #ffffff;
}
.section-step .section-step__title {
  margin-bottom: 16px;
  text-align: center;
}
@media (max-width: 480px) {
  .section-step .section-step__title {
    display: none;
  }
}
.section-step .section-step__description {
  margin-bottom: 32px;
  text-align: center;
}
@media (max-width: 480px) {
  .section-step .section-step__description {
    display: none;
  }
}
.section-step .section-step__wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
@media (max-width: 480px) {
  .section-step .section-step__wrapper {
    gap: 15px;
  }
}
.section-step .section-step__col-2 {
  display: flex;
  flex-direction: row;
  gap: 32px;
}
@media (max-width: 480px) {
  .section-step .section-step__col-2 {
    flex-direction: column;
    gap: 15px;
  }
}
@media (max-width: 768px) {
  .section-step .section-step__col-2--col {
    flex-direction: column;
    gap: 15px;
  }
}
.section-step .section-step__btn {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 16px;
  margin-bottom: 35px;
}
@media (max-width: 480px) {
  .section-step .section-step__btn {
    margin-bottom: 20px;
  }
}
@media (max-width: 480px) {
  .section-step .section-step__btn-blue {
    width: 100%;
  }
}

/* ----------------------------- section-profile ----------------------------- */
.section-profile .section-profile__title {
  margin-bottom: 24px;
  text-align: center;
}
.section-profile .section-profile__description {
  margin-bottom: 40px;
  text-align: center;
}
.section-profile .section-profile__form-group {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.section-profile .section-profile__btn {
  width: 100%;
  margin-top: 40px;
}

.section-realty-images__tips {
  position: absolute;
  top: 258px;
  right: 40px;
  width: 258px;
  height: 154px;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #b7b8be;
}
@media all and (max-width: 1300px) {
  .section-realty-images__tips {
    width: 138px;
  }
}
@media all and (max-width: 1250px) and (min-width: 1200px) {
  .section-realty-images__tips {
    width: 99px;
  }
}
@media (max-width: 480px) {
  .section-realty-images__tips {
    position: relative;
    top: 0;
    left: 0;
  }
}
.section-realty-images__tips .section-realty-images__tips-header {
  margin-left: 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.section-realty-images__tips .section-realty-images__tips-icon ::ng-deep mat-icon {
  width: 24px;
  height: 24px;
}
.section-realty-images__tips .section-realty-images__tips-description {
  margin-left: 16px;
  width: 257px;
}
@media all and (max-width: 1300px) {
  .section-realty-images__tips .section-realty-images__tips-description {
    width: 138px;
  }
}

.section-realty-images__formats {
  text-align: center;
  top: 160px;
  right: 40px;
  color: #b7b8be;
  margin-bottom: 20px;
}

.section-realty-images {
  width: 100%;
}
.section-realty-images .section-realty-images__container {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  margin-bottom: 40px;
}
.section-realty-images .section-realty-images__container > * {
  flex: 1 1 273px;
  max-width: 273px;
}
@media all and (max-width: 1200px) {
  .section-realty-images .section-realty-images__container > * {
    flex: 1 1 300px;
    max-width: 300px;
  }
}
@media (max-width: 768px) {
  .section-realty-images .section-realty-images__container > * {
    flex: 1 1 320px;
    max-width: 320px;
  }
}
.section-realty-images .section-realty-images__btns {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
}
@media (max-width: 768px) {
  .section-realty-images .section-realty-images__btns {
    justify-content: center;
    padding: 20px 21px;
    align-items: center;
  }
}

.section-expose .section-expose__card-expose {
  width: 100%;
  position: fixed;
}

.section-settings .section-settings__card-expose {
  width: 100%;
}

.summary-page__section .summary-page__header-data {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
@media (max-width: 480px) {
  .summary-page__section .summary-page__header-data {
    align-items: baseline;
    justify-content: center;
  }
}
@media (max-width: 768px) {
  .summary-page__section .summary-page__header-data {
    align-items: baseline;
    justify-content: center;
  }
}
.summary-page__section .summary-page__header-element {
  margin: 20px;
}

.document-page__section {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 40px;
  margin-bottom: 100px;
  height: 100vh;
}
@media (max-width: 480px) {
  .document-page__section {
    flex-direction: column;
    align-items: center;
    height: auto;
    margin-bottom: 0;
  }
}
.document-page__section .document-page__image-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.document-page__section .document-page__image-container-inner {
  padding: 40px;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  gap: 40px;
  height: 806px;
  max-width: 567px;
  position: relative;
  margin-bottom: 40px;
}
@media (max-width: 480px) {
  .document-page__section .document-page__image-container-inner {
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    min-width: 320px;
    padding: 20px;
    height: 100vh;
  }
}
.document-page__section .document-page__page-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 20px;
}
.document-page__section .document-page__page-container .document-page__image-info {
  position: relative;
  height: 46vh;
}
@media (max-width: 480px) {
  .document-page__section .document-page__page-container .document-page__image-info {
    height: 20vh;
  }
}
.document-page__section .document-page__page-container .document-page__image-info .document-page__image-label {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 109px;
  height: 52px;
  background-color: #0C213D;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.document-page__section .document-page__image {
  height: 100%;
  width: calc(100vw - 40px);
  max-width: 527px;
  max-height: 372px;
  object-fit: cover;
}
@media (max-width: 1040px) {
  .document-page__section .document-page__image {
    width: calc(100vw - 20px);
  }
}
.document-page__section .document-page__text-container-total {
  display: block;
  position: absolute;
  left: 32px;
  bottom: 32px;
  background-color: rgba(117, 119, 128, 0.4);
  cursor: pointer;
}
.document-page__section .document-page__text-container-total.document-page__text-container-total--relative {
  position: relative;
  background-color: #ffffff;
  left: 0;
  margin-top: 40px;
}
.document-page__section .document-page__text-container-total .document-page__regenerate-text {
  z-index: 2000;
  transition: 0.8s;
  transition-delay: 0.5s;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #001011;
  font-family: Plus Jakarta Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  margin-top: -50px;
  background: #fdfdfd;
  border-radius: 4px;
}
.document-page__section .document-page__text-container-total .document-page__regenerate-text.disabled {
  pointer-events: none;
  opacity: 0.7;
}
.document-page__section .document-page__image--title {
  width: 567px;
  height: 806px;
  object-fit: cover;
}
@media (max-width: 1040px) {
  .document-page__section .document-page__image--title {
    width: 400px;
    height: 600px;
  }
}
@media (max-width: 480px) {
  .document-page__section .document-page__image--title {
    width: 100vw;
    max-height: 100vh;
  }
}
.document-page__section .document-page__text-container {
  padding-right: 40px;
  padding-bottom: 40px;
}
.document-page__section .document-page__page-number {
  position: absolute;
  right: 40px;
  bottom: 40px;
  color: #b7b8be;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}
.document-page__section .document-page__text {
  max-width: 487px;
  color: #001011 !important;
  text-align: start;
}
@media (max-width: 480px) {
  .document-page__section .document-page__text {
    max-width: calc(100% - 40px);
    min-width: 320px;
    height: auto;
    padding-left: 20px;
  }
}
.document-page__section .document-page__text--plain {
  margin-bottom: 40px;
}
@media (max-width: 480px) {
  .document-page__section .document-page__text--plain {
    font-size: 14px !important;
    line-height: 24px !important;
  }
}
@media (max-width: 768px) {
  .document-page__section .document-page__text-title__32 {
    font-size: 26.6px;
    line-height: 35.5px;
  }
}
.document-page__section .document-page__text-title {
  margin-bottom: 24px;
  font-size: 20px !important;
  font-style: normal;
  font-weight: 600;
}
.document-page__section .document-page__regenerate-border:hover {
  border: 1px solid #2d00f7;
}
.document-page__section .document-page__text--title {
  color: #fdfdfd;
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
  line-height: 56px;
}
@media (max-width: 480px) {
  .document-page__section .document-page__text--title {
    font-size: 26.6px;
    line-height: 35.56px;
  }
}

.section-step__container--with-sidebar {
  display: flex;
  flex-wrap: wrap;
  max-height: 80vh;
  overflow-y: scroll;
}
.section-step__container--with-sidebar::-webkit-scrollbar-thumb {
  display: none;
}
.section-step__container--with-sidebar::-webkit-scrollbar {
  display: none;
}

.section-step__container--with-sidebar > .document-page__section {
  flex: 1 1 567px;
}
@media (max-width: 480px) {
  .section-step__container--with-sidebar > .document-page__section {
    flex: 1 1 320px;
  }
}

.section-step__container--with-sidebar > .document-page__section--first-page {
  flex: 1 1 1214px;
}
@media (max-width: 480px) {
  .section-step__container--with-sidebar > .document-page__section--first-page {
    flex: 1 1 320px;
  }
}

.document-page__section--centered {
  justify-content: center;
}

.document-page__section--left {
  justify-content: flex-start;
}

.document-page__section--left {
  justify-content: flex-end;
}

/* ----------------------------- section-generating ----------------------------- */
.section-generating .section-generating__title {
  color: var(--neutrals-rich-black, #001011);
  text-align: center;
  font-family: Plus Jakarta Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px;
  padding-bottom: 20px;
}
.section-generating .section-generating__text {
  color: var(--neutrals-metal-grey, #b7b8be);
  text-align: center;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
}

.loader-percent {
  color: var(--neutrals-rich-black, #001011);
  text-align: center;
  font-family: Plus Jakarta Sans;
  font-size: 70px;
  font-style: normal;
  font-weight: 500;
  line-height: 90px;
}

.loader-time {
  color: var(--neutrals-sonic-silver, #757780);
  text-align: center;
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.section-generating__content {
  padding-top: 140px;
  padding-bottom: 200px;
}

.expose-functional {
  margin-top: -222px;
}

.section-onboarding {
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;
  margin-bottom: 40px;
  text-align: center;
}
@media (max-width: 768px) {
  .section-onboarding {
    justify-content: center;
  }
}
.section-onboarding .section-onboarding__container {
  width: 67.5%;
  flex-wrap: wrap;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  gap: 32px;
  margin-bottom: 40px;
  text-align: center;
}
.section-onboarding .section-onboarding__container .section-onboarding__container-inner {
  max-width: 700px;
}
.section-onboarding .section-onboarding__container .section-onboarding__container-inner .section-onboarding__title {
  text-align: start;
}
@media (max-width: 768px) {
  .section-onboarding .section-onboarding__container .section-onboarding__container-inner .section-onboarding__title {
    font-size: 20px;
    margin-top: 20px;
    line-height: 30px;
    margin-right: 20px;
  }
}
.section-onboarding .section-onboarding__container .section-onboarding__container-inner .section-onboarding__description {
  text-align: start;
  margin-top: 24px;
}
@media (max-width: 768px) {
  .section-onboarding .section-onboarding__container .section-onboarding__container-inner .section-onboarding__description {
    font-size: 14px;
    margin-top: 5px;
  }
}
.section-onboarding .section-onboarding__container .section-onboarding__container-inner .section-onboarding__description a {
  color: #2d00f7;
  margin-left: 5px;
  text-decoration-line: underline;
}
.section-onboarding .section-onboarding__image-container {
  height: 100vh;
  max-width: 624px;
  width: 32.5%;
}
.section-onboarding .section-onboarding__image-container img {
  width: 100%;
  height: 100%;
}
.section-onboarding .section-onboarding__wrapper {
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}
@media (max-width: 480px) {
  .section-onboarding .section-onboarding__wrapper {
    gap: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
.section-onboarding .section-onboarding__info {
  text-align: justify;
  margin-bottom: 40px;
}
@media (max-width: 480px) {
  .section-onboarding .section-onboarding__info {
    font-size: 12px;
    margin-bottom: 5px;
  }
}

.summary-line {
  max-width: 900px;
  width: 100%;
  height: 1px;
  background: #ebedef;
}

.summary-page_line {
  max-width: 900px;
  width: 100%;
  height: 1px;
  background: #ebedef;
}
@media (max-width: 480px) {
  .summary-page_line {
    max-width: 900px;
    width: 100%;
    height: 1px;
    background: #ebedef;
    align-items: flex-start;
    align-content: flex-start;
    gap: 20px;
    flex-wrap: wrap;
    display: flex;
    z-index: 3;
    position: absolute;
    left: 0;
  }
}
@media (max-width: 768px) {
  .summary-page_line {
    max-width: 900px;
    width: 100%;
    height: 1px;
    background: #ebedef;
    align-items: flex-start;
    align-content: flex-start;
    gap: 20px;
    flex-wrap: wrap;
    display: flex;
    z-index: 3;
    position: absolute;
    left: 0;
  }
}

.summary-page_items {
  display: flex;
  flex-direction: row;
  gap: 89px;
}
@media (max-width: 480px) {
  .summary-page_items {
    flex-direction: column;
    gap: 0px;
    display: grid;
    place-content: center;
  }
}
@media (max-width: 768px) {
  .summary-page_items {
    flex-direction: column;
    gap: 0px;
    display: grid;
    place-content: center;
  }
}

.summary-page_column {
  display: flex;
  flex-direction: column;
}

.summary-page_gen {
  display: flex;
  justify-content: center;
}

.summary-page_hashtag-row {
  display: flex;
  flex-direction: row;
  padding: 24px 0 40px;
  gap: 8px;
}
@media (max-width: 480px) {
  .summary-page_hashtag-row {
    flex-wrap: wrap;
  }
}
@media (max-width: 768px) {
  .summary-page_hashtag-row {
    flex-wrap: wrap;
  }
}

.summary-page_hashtag-date {
  display: flex;
  padding: 8px;
  align-items: center;
  border-radius: 4px;
  background: var(--neutrals-soft-grey, #ebedef);
}

.name-center {
  display: flex;
  justify-content: center;
}

.summary-page_center {
  display: flex;
  justify-content: center;
}
@media (max-width: 480px) {
  .summary-page_center {
    justify-content: flex-start;
    display: flex;
  }
}
@media (max-width: 768px) {
  .summary-page_center {
    justify-content: flex-start;
    display: flex;
  }
}

.summary-page_gap {
  gap: 100px;
}
@media (max-width: 480px) {
  .summary-page_gap {
    gap: 0px;
  }
}
@media (max-width: 768px) {
  .summary-page_gap {
    gap: 0px;
  }
}

@media (max-width: 480px) {
  .price-row {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 768px) {
  .price-row {
    display: flex;
    flex-direction: column;
  }
}

.summary-page_price-column {
  display: contents;
}
@media all and (max-width: 768px) {
  .summary-page_price-column {
    display: flex;
    flex-direction: column;
  }
}

.summary-page_price-column-reverse {
  display: contents;
}
@media all and (max-width: 768px) {
  .summary-page_price-column-reverse {
    display: flex;
    flex-direction: column-reverse;
  }
}

@media (max-width: 480px) {
  .summary-page_hidden-line {
    display: none;
  }
}
@media (max-width: 768px) {
  .summary-page_hidden-line {
    display: none;
  }
}

.summary-step__title {
  text-align: center;
}

.summary-page_price {
  color: var(--neutrals-rich-black, #001011);
  text-align: center;
  font-family: Plus Jakarta Sans;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
}
@media (max-width: 480px) {
  .summary-page_price {
    justify-content: flex-start;
    display: flex;
  }
}
@media (max-width: 768px) {
  .summary-page_price {
    justify-content: flex-start;
    display: flex;
  }
}

.summary-page_mb-title {
  display: block;
}
@media (max-width: 480px) {
  .summary-page_mb-title {
    display: none;
  }
}
@media (max-width: 768px) {
  .summary-page_mb-title {
    display: none;
  }
}

.summary-page_item--info {
  color: var(--neutrals-rich-black, #001011);
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  width: 400px;
  padding-bottom: 16px;
  display: flex;
}
@media (max-width: 480px) {
  .summary-page_item--info {
    width: auto;
  }
}
@media (max-width: 768px) {
  .summary-page_item--info {
    width: auto;
  }
}

.summary-page_item {
  color: var(--neutrals-sonic-silver, #757780);
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  width: 157px;
}

.summary-page_hashtag-text {
  color: var(--neutrals-sonic-silver, #757780);
  font-family: Plus Jakarta Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}

/* ------------------------------- wrapper-mwp ------------------------------- */
.wrapper-mwp {
  height: 100%;
  background-image: url("/assets/images/mvp/mvp-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  overflow-y: auto;
}
.wrapper-mwp .wrapper-mwp__container {
  display: flex;
  flex-direction: column;
  gap: 140px;
  height: 100%;
  padding: 70px 30px 40px;
}
@media (max-width: 480px) {
  .wrapper-mwp .wrapper-mwp__container {
    justify-content: space-between;
    gap: 60px;
  }
}
.wrapper-mwp .wrapper-mwp__container--mvp-5 {
  padding: 70px 0 40px;
}
.wrapper-mwp--mvp-5 {
  background: linear-gradient(#020710, #000000);
}

/* ------------------------------- logo-mvp ------------------------------- */
.logo-mvp {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.logo-mvp .logo-mvp__title {
  font-family: "Averta CY", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #ffffff;
}

.wrapper-expose {
  height: 100%;
  overflow-y: auto;
  background-color: #ffffff;
}
@media (max-width: 768px) {
  .wrapper-expose.wrapper-expose__document-wrapper {
    height: auto;
  }
}

.wrapper-settings {
  height: 100vh;
  overflow-y: auto;
  background-color: #ffffff;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  border-right: 1px solid #ebedef;
  display: block;
}
@media (max-width: 768px) {
  .wrapper-settings {
    display: none;
  }
}

.mobile-sidebar {
  display: none;
}
@media (max-width: 768px) {
  .mobile-sidebar {
    display: block;
  }
}

.wrapper-menu {
  height: 100%;
  overflow-y: auto;
}

/* ------------------------------- btn-group ------------------------------- */
.btn-group {
  display: flex;
  flex-direction: row;
  min-height: 52px;
  height: 100%;
  border-radius: 4px;
}
.btn-group::-webkit-scrollbar-thumb {
  display: none;
}
.btn-group::-webkit-scrollbar {
  display: none;
}
@media (max-width: 768px) {
  .btn-group {
    margin: 0 -20px;
    overflow: auto;
  }
  .btn-group .btn-group__option:first-child {
    margin-left: 20px;
  }
  .btn-group .btn-group__option:last-child {
    margin-right: 20px;
  }
}
.btn-group .btn-group__option {
  position: relative;
  width: 100%;
}
.btn-group .btn-group__option:first-child .button-type {
  border-left: 1px solid #b7b8be;
  border-radius: 3px 0 0 3px;
}
.btn-group .btn-group__option:first-child .button-type:hover {
  border-left: 1px solid #2d00f7;
}
.btn-group .btn-group__option:nth-child(1n+2) .button-type {
  border-left: 1px solid #b7b8be;
}
.btn-group .btn-group__option:nth-child(1n+2) .button-type:hover {
  border-left: 1px solid #2d00f7;
}
.btn-group .btn-group__option:last-child .button-type {
  border-right: 1px solid #b7b8be;
  border-radius: 0 3px 3px 0;
}
.btn-group .btn-group__option:last-child .button-type:hover {
  border-right: 1px solid #2d00f7;
}
.btn-group .btn-group__input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  appearance: none;
  cursor: pointer;
}
.btn-group .btn-group__input:checked + .button-type {
  color: #ffffff;
  border: 1px solid #2d00f7;
  background: #2d00f7;
  transition: all 0.1s ease-in-out;
}

/* ------------------------------- wrapper-expose-step ------------------------------- */
.wrapper-expose-step .wrapper-expose-step__container {
  padding-top: 80px;
  padding-bottom: 47px;
}
@media (max-width: 768px) {
  .wrapper-expose-step .wrapper-expose-step__container {
    padding-top: 20px;
  }
}
.wrapper-onboarding-step .wrapper-onboarding__container {
  width: 100%;
}

.animate-mvp .animate-mvp__animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  transition: min-height 1.3s ease-in-out;
}
.animate-mvp .animate-mvp__slide-in-right {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
  transition: min-height 1.3s ease-in-out;
}
@-webkit-keyframes slideInRight {
  from {
    --webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }
  to {
    --webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideInRight {
  from {
    --webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }
  to {
    --webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.wrapper-expose-nav__spinner {
  max-width: 900px;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.profile-menu__container {
  position: absolute;
  right: 40px;
  top: 40px;
}
.profile-menu__container .mobile-sidebar__menu {
  width: 44px;
  height: 44px;
  background-color: #2d00f7;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 480px) {
  .profile-menu__container .mobile-sidebar__menu {
    width: 34px;
    height: 34px;
  }
}
@media (max-width: 480px) {
  .profile-menu__container .mobile-sidebar__document-menu {
    width: 24px !important;
    height: 24px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/* ------------------------------- wrapper-expose-generating ------------------------------- */
/* ------------------------------- wrapper-onboarding ------------------------------- */
.wrapper-onboarding {
  height: 100%;
}
.wrapper-onboarding .wrapper-onboarding__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 40px;
}
.wrapper-onboarding .wrapper-onboarding__main__title {
  text-align: center;
}
.wrapper-onboarding .wrapper-onboarding__main {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;
}